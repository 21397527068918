import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

Vue.use(VueRouter);

let homeRoute = {
    path: '/',
    name: 'home',
    component: () => import('../views/Main.vue')
}
if(process.env.VUE_APP_SITE_TYPE === 'landing') {
    homeRoute = {
        path: '/',
        name: 'home',
        component: () => import('../views/Start.vue')
    }
}
const getadventureHosts = JSON.parse(process.env.VUE_APP_GETADVENTURE_HOSTS);
if(getadventureHosts.indexOf(window.location.host) < 0) {
    homeRoute = {
        path: '/',
        name: 'home',
        component: () => import('../views/trip/DetailHost.vue'),
        meta: { layout: 'LayoutEmpty' }
    }
}

const routes = [
    homeRoute, {
        path: '/trips',
        name: 'trip-list',
        component: () => import('../views/trip/List.vue')
        // redirect: {
        //     name: 'configurator'
        // }
    }, {
        path: '/trip/create',
        name: 'trip-create',
        component: () => import('../views/trip/Configurator.vue')
    }, {
        path: '/start',
        name: 'start',
        component: () => import('../views/Start.vue')
    }, {
        path: '/contacts',
        name: 'contacts',
        component: () => import('../views/Contacts.vue')
    }, {
        path: '/instruction',
        name: 'instruction',
        component: () => import('../views/Instruction.vue')
    }, {
        path: '/experts',
        name: 'experts',
        component: () => import('../views/Experts.vue')
    }, {
        path: '/business',
        name: 'business',
        component: () => import('../views/Business.vue')
    }, {
        path: '/operator',
        name: 'operator',
        component: () => import('../views/operators/Operator.vue')
    }, {
        path: '/operator/requirements',
        name: 'requirements',
        component: () => import('../views/operators/Requirements.vue')
    }, {
        path: '/operator/bookings',
        name: 'operator-bookings',
        component: () => import('../views/operators/Bookings.vue')
    }, {
        path: '/operator/questions',
        name: 'operator-questions',
        component: () => import('../views/operators/Questions.vue')
    }, {
        path: '/operator/trip-requests',
        name: 'operator-trip-requests',
        component: () => import('../views/operators/TripRequests.vue')
    }, {
        path: '/operator/blogger-trip-choose/:tripRequestId',
        name: 'operator-blogger-trip-choose',
        component: () => import('../views/operators/BloggerTripChoose.vue')
    }, {
        path: '/operator/blogger-trip/:id',
        name: 'operator-blogger-trip',
        component: () => import('../views/operators/BloggerTrip.vue')
    }, {
        path: '/how-it-work',
        name: 'how-it-work',
        component: () => import('../views/HowItWork.vue')
    }, {
        path: '/questions',
        name: 'questions',
        component: () => import('../views/Questions.vue')
    }, {
        path: '/benefits',
        name: 'benefits',
        component: () => import('../views/Benefits.vue')
    }, {
        path: '/differences',
        name: 'differences',
        component: () => import('../views/Differences.vue')
    }, {
        path: '/philosophy',
        name: 'philosophy',
        component: () => import('../views/Philosophy.vue')
    }, {
        path: '/about',
        name: 'about',
        component: () => import('../views/About.vue')
    }, {
        path: '/trip/:id/edit',
        name: 'trip-edit',
        component: () => import('../views/trip/Configurator.vue')
    }, {
        path: '/trip/bloggers/:code',
        name: 'trip-bloggers',
        component: () => import('../views/trip/Detail.vue')
    }, {
        path: '/trips/bloggers',
        name: 'bloggers-trip-list',
        component: () => import('../views/trip/List.vue')
    }, {
        path: '/trip/:code',
        name: 'trip',
        component: () => import('../views/trip/Detail.vue')
    }, {
        path: '/trip/pdf/:code',
        name: 'trip-pdf',
        component: () => import('../views/trip/DetailPDF.vue'),
        meta: { layout: 'LayoutEmpty' }
    }, {
        path: '/site/:code',
        name: 'trip-site',
        component: () => import('../views/trip/DetailHost.vue'),
        meta: { layout: 'LayoutEmpty' }
    }, {
        path: '/trip/:code/review',
        name: 'trip-review',
        component: () => import('../views/trip/Review.vue')
    }, {
        path: '/trip/:trip_code/booking/:booking_id',
        name: 'trip-booking',
        component: () => import('../views/trip/Booking.vue')
    }, {
        path: '/user/authorization',
        name: 'user-authorization',
        component: () => import('../views/user/Authorization.vue')
    }, {
        path: '/user/confirmation',
        name: 'user-confirmation',
        component: () => import('../views/user/Confirmation.vue')
    }, {
        path: '/user/profile',
        name: 'user-profile',
        component: () => import('../views/user/Profile.vue')
    }, {
        path: '/user/profile/edit',
        name: 'user-profile-edit',
        component: () => import('../views/user/ProfileEdit.vue')
    }, {
        path: '/user/trips',
        name: 'user-trips',
        component: () => import('../views/user/Trips.vue')
    }, {
        path: '/user/favorites',
        name: 'user-favorites',
        component: () => import('../views/user/Favorites.vue')
    }, {
        path: '/user/bookings',
        name: 'user-bookings',
        component: () => import('../views/user/Bookings.vue')
    }, {
        path: '/user/documents',
        name: 'user-documents',
        component: () => import('../views/user/Documents.vue')
    }, {
        path: '/user/feedbacks/organizer',
        name: 'user-feedbacks-organizer',
        component: () => import('../views/user/FeedbacksOrganizer.vue')
    }, {
        path: '/user/chats/:chat_id',
        name: 'user-chat',
        component: () => import('../views/user/Chats.vue'),
        meta: { layout: 'LayoutMobileHeaderOnly' }
    }, {
        path: '/user/chats',
        name: 'user-chats',
        component: () => import('../views/user/Chats.vue'),
        meta: { layout: 'LayoutMobileHeaderOnly' }
    }, {
        path: '/user/events',
        name: 'user-events',
        component: () => import('../views/user/Events.vue')
    }, {
        path: '/blogger/authorization',
        name: 'blogger-authorization',
        component: () => import('../views/bloggers/Auth.vue'),
        meta: { show: 'authorization' }
    }, {
        path: '/blogger/registration',
        name: 'blogger-registration',
        component: () => import('../views/bloggers/Auth.vue'),
        meta: { show: 'registration' }
    }, {
        path: '/blogger/audience',
        name: 'blogger-audience',
        component: () => import('../views/bloggers/Audience.vue'),
        meta: { layout: 'LayoutBlogger' }
    }, {
        path: '/blogger/waiting',
        name: 'waiting-list',
        component: () => import('../views/bloggers/WaitingList.vue'),
        meta: { layout: 'LayoutBlogger' }
    }, {
        path: '/blogger/resources',
        name: 'blogger-resources-list',
        component: () => import('../views/bloggers/Resources.vue'),
        meta: { layout: 'LayoutBlogger' }
    }, {
        path: '/waiting-form/:social/:custom',
        name: 'blogger-waiting-form',
        component: () => import('../views/bloggers/WaitingForm.vue'),
        meta: { layout: 'LayoutEmpty' }
    }, {
        path: '/blogger/routes',
        name: 'blogger-routes',
        component: () => import('../views/bloggers/Routes.vue'),
        meta: { layout: 'LayoutBlogger' }
    }, {
        path: '/blogger/route/:code',
        name: 'blogger-route',
        component: () => import('../views/bloggers/Route.vue'),
        meta: { layout: 'LayoutBlogger' }
    }, {
        path: '/blogger/trips/my/:tab',
        name: 'blogger-trips-my-tab',
        component: () => import('../views/bloggers/MyTrips.vue'),
        meta: { layout: 'LayoutBlogger' }
    }, {
        path: '/blogger/trips/my',
        name: 'blogger-trips-my',
        component: () => import('../views/bloggers/MyTrips.vue'),
        meta: { layout: 'LayoutBlogger' }
    }, {
        path: '/blogger/trips/request',
        name: 'trips-request',
        component: () => import('../views/bloggers/TripRequest.vue'),
        meta: { layout: 'LayoutEmpty' }
    }, {
        path: '/blogger/trips/request/:trip',
        name: 'trips-request-trip',
        component: () => import('../views/bloggers/TripRequest.vue'),
        meta: { layout: 'LayoutEmpty' }
    }, {
        path: '/blogger/trip/:id',
        name: 'blogger-trip',
        component: () => import('../views/bloggers/BloggerTrip.vue'),
        meta: { layout: 'LayoutBlogger' }
    }, {
        path: '/quiz/:custom/:social',
        name: 'blogger-quiz',
        component: () => import('../views/bloggers/Quiz.vue'),
        meta: { layout: 'LayoutEmpty' }
    },{
        path: '/quiz/spanish/:custom/:social',
        name: 'blogger-spanish-quiz',
        component: () => import('../views/bloggers/QuizSpanish.vue'),
        meta: { layout: 'LayoutEmpty' }
    },{
        path: '/quiz/hindi/:custom/:social',
        name: 'blogger-hindi-quiz',
        component: () => import('../views/bloggers/QuizHindi.vue'),
        meta: { layout: 'LayoutEmpty' }
    },{
        path: '/quiz/korean/:custom/:social',
        name: 'blogger-korean-quiz',
        component: () => import('../views/bloggers/QuizKorean.vue'),
        meta: { layout: 'LayoutEmpty' }
    },{
        path: '/quiz/russian/:custom/:social',
        name: 'blogger-russian-quiz',
        component: () => import('../views/bloggers/QuizRussian.vue'),
        meta: { layout: 'LayoutEmpty' }
    }, {
        path: '/news',
        name: 'news-list',
        component: () => import('../views/news/List.vue')
    }, {
        path: '/news/:code',
        name: 'news',
        component: () => import('../views/news/Detail.vue')
    }, {
        path: '/reports',
        name: 'reports-list',
        component: () => import('../views/reports/List.vue')
    }, {
        path: '/reports/:code',
        name: 'report',
        component: () => import('../views/reports/Detail.vue')
    }, {
        path: '/reviews',
        name: 'reviews-list',
        component: () => import('../views/reviews/List.vue')
    }, {
        path: '/reviews/:code',
        name: 'reviews',
        component: () => import('../views/reviews/Detail.vue')
    }, {
        path: '/booking-terms',
        name: 'booking-terms',
        component: () => import('../views/BookingTermsTraveler.vue')
    }, {
        path: '/booking-terms-host',
        name: 'booking-terms-host',
        component: () => import('../views/BookingTermsHost.vue')
    }, {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import('../views/PrivacyPolicy.vue')
    }
]

const router = new VueRouter({
    routes,
    base: '/',   
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

export default router
