import axios from '../../plugins/axios';
import moment from '@/plugins/moment';

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: {}
    },

    mutations: {
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        CLEAR_ENTITIES(state, payload) {
            state.entities = [];
        },
        CLEAR_ENTITY(state) {
            state.entity = {};
        },
    },

    actions: {
        async fetch({ commit }, { filter = {}, sorting = null, limit = null, page = null }) {
            const { data } = await axios.get('/front/blogger-trips', { params: { filter, sorting, limit, page } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/front/blogger-trips/${ id }`);
            commit('SET_ENTITY', data);
        },
        async getByTripRequest({ commit }, { blogger, tripRequest }) {
            const { data } = await axios.get(`/front/blogger-trips/by-trip-request/${blogger}/${tripRequest}`);
            commit('SET_ENTITY', data);
            return data;
        },
        async save({ commit, state }) {
            if(state.entity._id) {
                await axios.put(`/front/blogger-trips/${ state.entity._id }`, state.entity);
            } else {
                await axios.post('/front/blogger-trips', state.entity);
            }
        },
    },

    getters: {}
}
