import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: {}
    },

    mutations: {
        CLEAR_ENTITY(state) {
            state.entity = {};
        },
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
    },

    actions: {
        async fetch({ commit }, { filter = {}, sorting = null }) {
            const { data } = await axios.get('/front/reports', { params: { filter, sorting } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/front/reports/${ id }`);
            commit('SET_ENTITY', data);
        },
        async getByCode({ commit }, { code }) {
            const { data } = await axios.get(`/front/reports/code/${ code }`);
            commit('SET_ENTITY', data);
        },
    },

    getters: {}
}
