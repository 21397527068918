<template>
    <div class="mobile-footer" v-if="pageTemplate">
        <div class="mobile-footer__links">
            <!-- <div class="mobile-footer__links__link mobile-footer__links__tours">
                Путешествия <img class="mobile-footer__links__link__arrow" src="../../assets/ArrowRight.svg" />
            </div>
            <div class="mobile-footer__links__link mobile-footer__links__safety">
                Информация о безопасности <img class="mobile-footer__links__link__arrow" src="../../assets/ArrowRight.svg" />
            </div>
            <div class="mobile-footer__links__link mobile-footer__links__country">
                Отмена в период пандемии <img class="mobile-footer__links__link__arrow" src="../../assets/ArrowRight.svg" />
            </div> -->
            <div v-if="pageTemplate.col1 && pageTemplate.col1.show" class="mobile-footer__links__main">
                <div class="mobile-footer__links__contacts__logo"></div>
                <ul v-if="pageTemplate.col1.links" class="mobile-footer__links__list">
                    <template v-for="(link, index) in pageTemplate.col1.links">
                        <li
                            :key="`col1-links-${index}`"
                            class="mobile-footer__links__item"
                            @click="goTo(link.link)">{{ link[lang] }}</li>
                    </template>
                </ul>
            </div>
            <div v-if="pageTemplate.col2 && pageTemplate.col2.show" class="mobile-footer__links__type">
                <div class="mobile-footer__links__block-title">{{ pageTemplate.col2[lang] }}</div>
                <ul v-if="pageTemplate.col2.links" class="mobile-footer__links__list">
                    <template v-for="(link, index) in pageTemplate.col2.links">
                        <li
                            :key="`col2-links-${index}`"
                            class="mobile-footer__links__item"
                            @click="goTo(link.link)">{{ link[lang] }}</li>
                    </template>
                </ul>
            </div>
            <div v-if="pageTemplate.col3 && pageTemplate.col3.show" class="mobile-footer__links__country">
                <div class="mobile-footer__links__block-title">{{ pageTemplate.col3[lang] }}</div>
                <ul v-if="pageTemplate.col3.links" class="mobile-footer__links__list">
                    <template v-for="(link, index) in pageTemplate.col3.links">
                        <li
                            :key="`col3-links-${index}`"
                            class="mobile-footer__links__item"
                            @click="goTo(link.link)">{{ link[lang] }}</li>
                    </template>
                </ul>
            </div>
            <div v-if="pageTemplate.contacts" class="mobile-footer__links__contacts">
                <div class="footer__links__block-title">{{ pageTemplate.contacts.title[lang] }}</div>
                <div class="footer__links__contacts__key">{{ pageTemplate.contacts.phoneTitle[lang] }}</div>
                <div class="footer__links__contacts__value">{{ pageTemplate.contacts.phone[lang] }}</div>
                <div class="footer__links__contacts__key">{{ pageTemplate.contacts.emailTitle[lang] }}</div>
                <div class="footer__links__contacts__value"><a :href="`mailto:${ pageTemplate.contacts.email[lang] }`">{{ pageTemplate.contacts.email[lang] }}</a></div>
                <div class="footer__links__contacts__block">
                    <div class="footer__links__contacts__social">
                        <a
                            v-if="pageTemplate.contacts.instagram[lang]"
                            :href="pageTemplate.contacts.instagram[lang]"
                            target="_blank">
                            <div class="footer__links__contacts__social__item instagram"></div>
                        </a>
                        <a
                            v-if="pageTemplate.contacts.fb[lang]"
                            :href="pageTemplate.contacts.fb[lang]"
                            target="_blank">
                            <div class="footer__links__contacts__social__item facebook"></div>
                        </a>
                        <a
                            v-if="pageTemplate.contacts.vk[lang]"
                            :href="pageTemplate.contacts.vk[lang]"
                            target="_blank">
                            <div class="footer__links__contacts__social__item VK"></div>
                        </a>
                        <a
                            v-if="pageTemplate.contacts.telegram[lang]"
                            :href="pageTemplate.contacts.telegram[lang]"
                            target="_blank">
                            <div class="footer__links__contacts__social__item telegram"></div>
                        </a>
                    </div>
                    <!-- <div class="footer__links__contacts__lang" v-if="isMarketplace">
                        <span class="footer__links__contacts__lang__active" v-if="lang === 'ru'">
                            рус
                        </span>
                        <a :href="url.ru" v-else>
                            рус
                        </a>
                        &nbsp;|&nbsp;
                        <span class="footer__links__contacts__lang__active" v-if="lang === 'en'">
                            eng
                        </span>
                        <a :href="url.en" v-else>
                            eng
                        </a>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="mobile-footer__static" v-if="pageTemplate.technical">
            <div class="footer__static__item">© {{ pageTemplate.technical.title[lang] }}</div>
            <div class="footer__static__item" @click="goTo('booking-terms')">{{ pageTemplate.technical.BookingTerms[lang] }}</div>
            <div class="footer__static__item" @click="goTo('privacy-policy')">{{ pageTemplate.technical.PrivacyPolicy[lang] }}</div>
            <!-- <div class="mobile-footer__static__affetta">
                <div class="mobile-footer__static__item">Дизайн от Affetta</div>
                <img src="../../assets/AFFETTA.svg" />
            </div> -->
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    export default {
        name: 'MobileFooter',
        data: () => ({
            url: {
                ru: process.env.VUE_APP_RU_URL,
                en: process.env.VUE_APP_EN_URL
            }
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('pageTemplates', {
                pageTemplate: state => state.footer
            }),
            isMarketplace() {
                return process.env.VUE_APP_SITE_TYPE === 'marketplace';
            }
        },
        methods: {
            async goTo(link) {
                if(link && link.substr(0, 4) === 'http') {
                    window.open(link, '_blank');
                    return;
                }
                this.$router.push(link);
            }
        }
    }
</script>

<style lang='scss'>
.mobile-footer {
    border-top: 1px solid #EBEBEB;
    &__links {
        width: 100%;
        padding-bottom: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 20px 30px;
        height: auto;
        &__link {
            position: relative;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: -0.02em;
            color: #273155;
            opacity: 0.7;
            padding: 18px 0 18px;
            border-bottom: 1px solid #e2e2e2;

            &__arrow {
                position: absolute;
                right: 10px;
                top: 20px;
            }
        }
        &__block-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.02em;
            color: #273155;
            text-align: left;
            margin-bottom: 18px;
        }
        &__block-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.02em;
            color: #273155;
            text-align: left;
            margin-bottom: 18px;
            margin-top: 20px;
        }
        &__list {
            list-style-type: none;
            text-align: left;
            padding-left: 0 !important;
        }
        &__type {
            margin-bottom: 10px;
        }
        &__country {
            margin-bottom: 10px;
        }
        &__item {
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: -0.02em;
            color: #A9AEB2;
            margin-bottom: 12px;
            cursor: pointer;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        &__contacts {
            text-align: left;
            margin-top: 20px;
            &__logo {
                width: 170px;
                height: 25px;
                margin-bottom: 18px;
                background: url(../../assets/Main-logo.svg);
                background-size:contain ;
                background-position: center;
                background-repeat: no-repeat;
                cursor: pointer;
                margin-top: 30px;
                margin-bottom: 20px;
            }
            &__key {
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: -0.02em;
                color:  #273155;
                opacity: 0.4;
                margin-bottom: 8px;
            }
            &__value {
                font-weight: 500;
                font-size: 18px;
                line-height: 115%;
                color: #273155;
                margin-bottom: 20px;
                > a {
                    text-decoration: none;
                    color: #273155 !important;
                }
            }
            &__block {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
            &__social {
                display: flex;
                flex-direction: row;

                &__item {
                    width: 32px;
                    height: 32px;
                    background-position: center;
                    background-repeat: no-repeat;
                    border: 1px solid #93A3B4;
                    border-radius: 50%;
                    box-sizing: border-box;
                    margin-right: 12px;
                    margin-top: 11px;
                    cursor: pointer;
                    &:last-of-type {
                        margin-right: none;
                    }
                    &.facebook {
                        background-image: url(../../assets/Facebook.svg);
                    }
                    &.VK{
                        background-image: url(../../assets/VK.svg);
                    }
                    &.telegram {
                        background-image: url(../../assets/Telegram.svg);
                        background-position: 6px 8px;
                    }
                    &.instagram {
                        background-image: url(../../assets/Instagram.svg);
                    }
                }
            }
            &__lang {
                display: flex;
                flex-direction: row;
                
                &__active {
                    color: #273155 !important;
                }
            }
        }
    }
    &__static {
        width: 100%;
        max-width: 1400px;
        display: flex;
        flex-direction: row;
        margin: auto;
        justify-content: space-between;
        border-top: 1px solid #EBEBEB;
        opacity: 0.5;
        @media all and (max-width: 768px) {
            flex-direction: column;
            padding: 12px 20px;
        }
        &__item {
            font-weight: normal;
            font-size: 14px;
            line-height: 140%;
            display: flex;
            align-items: center;
            color: #505872;
            margin-bottom: 12px;
        }
        &__affetta {
            display: flex;
            flex-direction: row;
        }
    }
}
</style>