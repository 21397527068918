const LANG = process.env.VUE_APP_SITE_LANGUAGE;

export const TRIP_STATUS = {
    DRAFT: 'draft',
    ACTIVE: 'active',
    MODERATED: 'moderated',
    NEED_EDITS: 'need_edits',
    NEED_USER: 'need_user',
    DOCUMENTS: 'documents',
    COMPLETED: 'completed',
    INACTIVE: 'inactive'
};

export const TRIP_STATUS_TITLE = {
    [TRIP_STATUS.DRAFT]: LANG === 'ru' ? 'Черновик' : 'Draft',
    [TRIP_STATUS.ACTIVE]: LANG === 'ru' ? 'Опубликован' : 'Active',
    [TRIP_STATUS.MODERATED]: LANG === 'ru' ? 'На модерации' : 'In moderation',
    [TRIP_STATUS.NEED_EDITS]: LANG === 'ru' ? 'Нужны правки' : 'Need edits',
    [TRIP_STATUS.NEED_USER]: LANG === 'ru' ? 'Не заполнен профиль' : 'Need user',
    [TRIP_STATUS.DOCUMENTS]: LANG === 'ru' ? 'Документы готовы' : 'Documents are ready',
    [TRIP_STATUS.COMPLETED]: LANG === 'ru' ? 'Завершен' : 'Completed',
    [TRIP_STATUS.INACTIVE]: LANG === 'ru' ? 'Завершен' : 'Inactive',
};

export const BLOGGER_TRIP_STATUS = {
    DRAFT: 'draft',
    ORGANIZER_MODERATED: 'organizer_moderated',
    BLOGGER_MODERATED: 'bloger_moderated',
    MODERATED: 'moderated',
    ACTIVE: 'active',
    COMPLETED: 'completed',
    DECLINED: 'declined',
};

export const BLOGGER_TRIP_STATUS_TITLE = {
    [BLOGGER_TRIP_STATUS.DRAFT]: LANG === 'ru' ? 'Черновик' : 'Draft',
    [BLOGGER_TRIP_STATUS.ORGANIZER_MODERATED]:  LANG === 'ru' ? 'Обрабатывается операторами' : 'Handled by operators',
    [BLOGGER_TRIP_STATUS.BLOGGER_MODERATED]:  LANG === 'ru' ? 'Ожидает подтверждения' : 'Confirmation pending',
    [BLOGGER_TRIP_STATUS.MODERATED]:  LANG === 'ru' ? 'На модерации' : 'In moderation',
    [BLOGGER_TRIP_STATUS.ACTIVE]:  LANG === 'ru' ? 'Одобрен' : 'Approved',
    [BLOGGER_TRIP_STATUS.COMPLETED]:  LANG === 'ru' ? 'Завершен' : 'Completed',
    [BLOGGER_TRIP_STATUS.DECLINED]:  LANG === 'ru' ? 'Отклонен' : 'Declined',
};

export const TRIP_LEVEL = {
    BEGINNER: 'beginner',
    ADVANCED: 'advanced',
    EXPERT: 'expert'
};

export const TRIP_LEVEL_TITLE = {
    [TRIP_LEVEL.BEGINNER]: LANG === 'ru' ? 'Стартовый' : 'Beginner',
    [TRIP_LEVEL.ADVANCED]: LANG === 'ru' ? 'Продвинутый' : 'Advanced',
    [TRIP_LEVEL.EXPERT]: LANG === 'ru' ? 'Экспертный' : 'Expert'
};

export const TRIP_LEVEL_QUALIFIED_COUNT = {
    BEGINNER: 50,
    ADVANCED: 200,
    EXPERT: 300
};

export const BOOKING_STATUS = {
    STARTED: 'started',
    FILLED: 'filled',
    DEPOSIT: 'deposit',
    PAID: 'paid',
    APPROVED: 'approved',
    NEED_REFUND: 'need_refund',
    CANCELED: 'canceled'
};

export const BOOKING_STATUS_TITLE = {
    [BOOKING_STATUS.STARTED]: LANG === 'ru' ? 'Начато оформление' : 'Started',
    [BOOKING_STATUS.FILLED]: LANG === 'ru' ? 'Предоплачен' : 'Filled',
    [BOOKING_STATUS.DEPOSIT]: LANG === 'ru' ? 'Предоплачен' : 'Deposit paid',
    [BOOKING_STATUS.PAID]: LANG === 'ru' ? 'Оплачен' : 'Paid',
    [BOOKING_STATUS.APPROVED]: LANG === 'ru' ? 'Одобрен' : 'Approved',
    [BOOKING_STATUS.NEED_REFUND]: LANG === 'ru' ? 'Ожидает возврата' : 'Awaiting refund',
    [BOOKING_STATUS.CANCELED]: LANG === 'ru' ? 'Отменен' : 'Canceled'
};

export const BOOKING_TYPE = {
    ARRIVAL: 'arrival',
    BLOGGER_TRIP: 'bloggerTrip'
};

export const PAYMENT_STATUS = {
    PROCESSING: 'processing',
    SUCCESS: 'success',
    FAIL: 'fail',
    REFUND: 'refund',
    CANCELED: 'canceled'
};

export const PAYMENT_STATUS_TITLE = {
    [PAYMENT_STATUS.PROCESSING]: LANG === 'ru' ? 'Обработка' : 'Processing',
    [PAYMENT_STATUS.SUCCESS]: LANG === 'ru' ? 'Успешно' : 'Success',
    [PAYMENT_STATUS.FAIL]: LANG === 'ru' ? 'Ошибка' : 'Fail',
    [PAYMENT_STATUS.REFUND]: LANG === 'ru' ? 'Возвращен' : 'Refund',
    [PAYMENT_STATUS.CANCELED]: LANG === 'ru' ? 'Отменен' : 'Canceled'
};

export const REVIEW_STATUS = {
    DRAFT: 'draft',
    MODERATED: 'moderated',
    ACTIVE: 'active',
    REJECTED: 'rejected'
};

export const REVIEW_STATUS_TITLE = {
    [REVIEW_STATUS.DRAFT]: LANG === 'ru' ? 'Черновик' : 'Draft',
    [REVIEW_STATUS.MODERATED]: LANG === 'ru' ? 'На модерации' : 'In moderation',
    [REVIEW_STATUS.ACTIVE]: LANG === 'ru' ? 'Опубликован' : 'Active',
    [REVIEW_STATUS.REJECTED]: LANG === 'ru' ? 'Отклонен' : 'Rejected'
};

export const CHAT_MESSAGE_STATUS = {
    CREATED: 'created',
    DELIVERED: 'delivered',
    READED: 'readed',
    DELETED: 'deleted'
};

export const CHAT_MESSAGE_STATUS_TITLE = {
    [REVIEW_STATUS.CREATED]: LANG === 'ru' ? 'Создано' : 'Created',
    [REVIEW_STATUS.DELIVERED]: LANG === 'ru' ? 'Доставлено' : 'Delivered',
    [REVIEW_STATUS.READED]: LANG === 'ru' ? 'Прочитано' : 'Readed',
    [REVIEW_STATUS.DELETED]: LANG === 'ru' ? 'Удалено' : 'Deleted'
};

export const NEWS_CONTENT_TYPE = {
    TEXT: 'text',
    PICTURE: 'picture',
    YOUTUBE: 'youtube'
};

export const DIRECTION_TYPE = {
    RUSSIA: 'russia',
    WORLD: 'world'
};

export const DIRECTION_TYPE_TITLE = {
    [DIRECTION_TYPE.RUSSIA]: LANG === 'ru' ? 'По России' : 'Russia trip',
    [DIRECTION_TYPE.WORLD]: LANG === 'ru' ? 'Международный тур' : 'World trip'
};

export const TRIP_TYPE = {
    TOUR_LEADER: 'tour_leader',
    HOST: 'host',
    TOUR_BLOGGER: 'tour_blogger',
};

export const TRIP_TYPE_TITLE = {
    [TRIP_TYPE.TOUR_LEADER]: 'TOUR LEADER',
    [TRIP_TYPE.HOST]: 'HOST',
    [TRIP_TYPE.TOUR_BLOGGER]: 'TOUR_BLOGGER'
};

export const TRIP_REQUEST_STATUS = {
    CREATED: 'created',
    ANSWERED: 'answered',
    FINISHED: 'finished'
};

export const FEEDBACK_TYPE = {
    CONSULTATION: 'consultation',
    DOWNLOAD_PROGRAM: 'download_program',
    PARTICIPATION: 'participation',
    INDIVIDUAL_TRIP: 'individual_trip',
    FAVORITE_ADD: 'favorite_add',
    BOOKING_START: 'booking_start'
};