<template>
    <v-app>
        <component :is="layout">
            <v-main>
                <router-view :key="$route.fullPath"/>
            </v-main>
        </component>
        <Preloader />
    </v-app>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { isMobile } from '@/helpers';

    import DesktopHeader from './components/desktop/Header.vue';
    import Header from './components/mobile/Header.vue';
    import Footer from './components/desktop/Footer.vue';
    import MobileFooter from './components/mobile/Footer.vue';
    import PopupLogin from './components/common/PopupLogin.vue';
    import Preloader from './components/common/Preloader.vue';


    export default {
        name: 'GetAdventure',
        metaInfo: {
            title: 'GetAdventures',
            titleTemplate: '%s | GetAdventures'
        },
        components: {
            DesktopHeader,
            Header,
            Footer,
            MobileFooter,
            PopupLogin,
            Preloader
        },
        data: () => ({
            isDesktop: false,
        }),
        computed: {
            ...mapState('auth', ['user']),
            layout() {
                const layoutName = this.$route.meta.layout || 'LayoutDefault';
                return () => import(`@/layouts/${layoutName}.vue`)
            },
            isMarketplace() {
                return process.env.VUE_APP_SITE_TYPE === 'marketplace';
            }
        },
        async mounted() {
            await store.dispatch('translates/fetch');
            await store.dispatch('dictionaries/fetch');
            await store.dispatch('pageTemplates/fetch');
            await store.dispatch('quiz/dictionaries');

            if(this.$route.query.action) {
                const { action, phone, code } = this.$route.query;
                if(action === 'auth' && phone && code) {
                    await store.dispatch('auth/adminLogin', { phone, code });
                    await this.$router.push({ name: 'home' });
                }
            }

            if(!this.user._id) {
                try {
                    await store.dispatch('auth/fetch');
                } catch(e) {}
            }

            if(this.user._id) {
                try {
                    await store.dispatch('events/summary');
                    await store.dispatch('events/fetch', {});
                } catch(e) {}
            }
            
            this.$root.$emit('preloaderHide');
        },
    }
</script>