import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        entities: [],
        entity: {
            trip: null,
            booking: null,
            amount: 0
        },
        intent: {
            secretKey: ''
        }
    },
    mutations: {
        CLEAR_ENTITIES(state) {
            state.entities = [];
            state.entitiesCount = 0;
        },
        CLEAR_ENTITY(state) {
            state.entity = {
                trip: null,
                booking: null,
                amount: 0
            };
        },
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = {
                ...state.entity,
                ...payload
            };
        },
        SET_INTENT(state, payload) {
            state.intent = {
                ...state.intent,
                ...payload
            };
        },
    },
    actions: {
        async fetch({ commit }, { filter = {} }) {
            const { data } = await axios.get('/front/payments', { filter });
            commit('SET_ENTITIES', data);
            return data;
        },
        async intentSucceded({ commit }, { clientSecret = '' }) {
            const { data } = await axios.put(`/front/payments/intent/succeded`, { clientSecret });
            return data ? data.booking : null;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/front/payments/${ id }`);
            commit('SET_ENTITY', data);
        },
        async save({ commit, state }) {
            let response = {};
            if(state.entity._id) {
                response = await axios.put(`/front/payments/${ state.entity._id }`, state.entity);
                commit('SET_ENTITY', response.data);
            } else {
                response = await axios.post('/front/payments', state.entity);
                commit('SET_ENTITY', response.data);
            }
            return response.data;
        },
    },

    getters: {}
}
