import axios from '../../plugins/axios';
import moment from '@/plugins/moment';

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: {
            user: '',
            externalUser: {
                firstname: '',
                lastname: ''
            },
            trip: '',
            date: '',
            rating: {
                overall: 0,
                route: 0,
                food: 0,
                accommodation: 0,
                transport: 0
            },
            text: '',
            pictures: [],
            proof: {
                link: '',
                picture: ''
            },
        }
    },

    mutations: {
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        CLEAR_ENTITY(state) {
            state.entity = {
                user: '',
                externalUser: {
                    firstname: '',
                    lastname: ''
                },
                trip: '',
                date: '',
                rating: {
                    overall: 0,
                    route: 0,
                    food: 0,
                    accommodation: 0,
                    transport: 0
                },
                text: '',
                puctures: [],
                proof: {
                    link: '',
                    picture: ''
                },
            };
        },
    },

    actions: {
        async fetch({ commit }, { filter = {}, sorting = null, limit = null, page = null }) {
            const { data } = await axios.get('/front/reviews', { params: { filter, sorting, limit, page } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/front/reviews/${ id }`);
            data.date = moment(data.date).format('YYYY-MM-DD');
            commit('SET_ENTITY', data);
        },
        async save({ commit, state }) {
            let response = {};
            if(state.entity._id) {
                response = await axios.put(`/front/reviews/${ state.entity._id }`, state.entity);
            } else {
                response = await axios.post('/front/reviews', state.entity);
            }
        },
        async setStatus({ commit, state }, { id, status }) {
            await axios.put(`/front/reviews/status/${ id }/${ status }`);
        },
        async delete({ commit }, { id }) {
            await axios.delete(`/front/reviews/${ id }`);
        },
    },

    getters: {}
}
